<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="importedImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>

    <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400">
        <img :src="importedImg3" :alt="altText" class="imgItem3" />
      </div>
    </div>
    <!-- <div class="imgBox4 min-w-1400">
      <div class="img4 max-w-1400">
        <img :src="importedImg4" :alt="altText" class="imgItem4" />
      </div>
    </div> -->
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/kasikvay/components/img/3_1.png";
import importedImg2 from "@/page/kasikvay/components/img/3_2.png";
import importedImg3 from "@/page/kasikvay/components/img/3_3.png";
export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.imgBox1
 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

.imgBox2,
.imgBox3
{
  display: flex;
  justify-content: center;
  width: 100%;
}
img {
  display: block;
}
.img1
 {
  width: 100%;
  overflow: hidden;
  max-width: 1620px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 80px;
}

.img2,
.img3
 {
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 80px;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  max-width: 100%;
}
.imgItem4 {
  max-width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
</style>
