<template>
  <nav class="navbar min-w-1400">
    <div class="secondBox max-w-1400">
      <div class="logo">
        <img
          :src="require('@/page/kasikvay/components/img/logo.png')"
          :alt="logo"
          class="logoImg"
        />
        <span class="title">Kasik Vay </span>
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">Trang đầu</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">Về chúng ta</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/clause' }" class="Li3">
          <router-link to="/clause" class="nav-link3">
            <span class="routeText3">sản phẩm và dịch vụ</span>
          </router-link>
        </li>
        <li
          :class="{ 'active-link': $route.path === '/questions' }"
          class="Li4"
        >
          <router-link to="/questions" class="nav-link4">
            <span class="routeText4">vấn đề thường gặp</span>
          </router-link>
        </li>

        <li
          :class="{ 'active-link': $route.path === '/protocol' }"
          class="lastLi"
        >
          <router-link to="/protocol" class="nav-link5">
            <span class="routeText5">Thỏa thuận riêng tư</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div style="height: 130px"></div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      logo: "NAM A ASSET MANAGEMENT COMPANY LIMITED",
      backgroundImage: "../../src/page/kasikvay/components/img/Rectangle.png",
      link1Color: "white", // 初始颜色，可以是你希望的颜色
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #fff;
}

.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 130px;
  padding: 0 15px;
}

.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
  margin-left: 30px;
}

.title {
  width: 280px;

  height: 24px;
  font-size: 20px;
  font-family: SF Pro Display-Medium;
  font-weight: 500;
  color: #333333;
  line-height: 23px;
  padding-left: 18px;
  text-transform: uppercase;
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 17px;
  font-family: SF Pro Display-Medium;
  font-weight: 500;
  color: #333333;
  line-height: 29px;
  text-transform: uppercase;
}

.routeText1 {
  padding-left: 8px;
  width: 85px;
}

.routeText2 {
  padding-left: 8px;
  width: 158px;
}

.routeText3 {
  padding-left: 14px;
  width: 160px;
}

.routeText4 {
  padding-left: 9px;
  width: 160px;
  text-align: center;
}
.routeText5 {
  width: 160px;
  text-align: center;
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;
  height: 39px;
  font-weight: 500;
  line-height: 20px;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}

.nav-links li {
  display: flex;
  padding: 6px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 39px;
  padding-left: 8px;
  padding-right: 8px;
}
.Li1 {
  width: 150px;
}
.Li2 {
  width: 160px;
}
.Li3 {
  width: 240px;
}
.Li4 {
  width: 240px;
}
.lastLi {
  width: 240px;
  margin-right: 0px;
}
.active-link {
  background: url("../../page/kasikvay/components/img/Rectangle.png");
  background-size: 100% 100%;
  /* 背景图片充满整个容器 */
  background-repeat: no-repeat;
}

.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  font-size: 18px;
  font-family: Inter-Extra Bold;
  font-weight: 600;
  color: #ffffff;
}
</style>