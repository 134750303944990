<template>
  <div id="app">
    <MyHeaderVue />
    <router-view></router-view>
    <MyFooterVue />
  </div>
</template>

<script>
// import MyHeaderVue from './page/chatchaimobile/MyHeader.vue'
// import MyFooterVue from './page/chatchaimobile/MyFooter.vue'

//jaidee官网
// import MyHeaderVue from './page/jaidee/MyHeader.vue'
// import MyFooterVue from './page/jaidee/MyFooter.vue'

//jaideeconnect
// import MyHeaderVue from './page/jaideeconnect/MyHeader.vue'
// import MyFooterVue from './page/jaideeconnect/MyFooter.vue'

//未命名
// import MyHeaderVue from './page/weimingm/MyHeader.vue'
// import MyFooterVue from './page/weimingm/MyFooter.vue'

//越南kasikvay
import MyHeaderVue from './page/kasikvay/MyHeader.vue'
import MyFooterVue from './page/kasikvay/MyFooter.vue'
export default {
  name: "App",
  components: {
    MyHeaderVue,
    MyFooterVue,
  },
};
</script>

<style>
#app {
  color: #2c3e50;
}
</style>
